import {
  AdditionalButton,
  EAdditionalButtonTheme,
  Container,
  EContainerVariant,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

import { useActions } from '../../..';

export type TAdditionalButtonConnectedProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'additional-button' }
>;

const AdditionalButtonConnected: React.FC<
  TAdditionalButtonConnectedProps
> = props => {
  const { buttons, theme } = props;
  const button = useActions(buttons)?.[0];
  return (
    <Container variant={EContainerVariant.Full}>
      <AdditionalButton
        theme={theme as EAdditionalButtonTheme}
        button={button}
      />
    </Container>
  );
};

export default AdditionalButtonConnected;
